import * as React from "react";
import { Link } from "gatsby";
import isAbsoluteURL from "is-absolute-url";
import * as styles from "./button.module.css";

export default function Button({ href, text, section = "", variant = "primary" }) {
  const buttonStyle = variant === "primary" ? styles.buttonPrimary : styles.buttonSecondary;
  const buttonSection = section === "hero" ? styles.buttonHero : "";

  if (isAbsoluteURL(href)) {
    return (
      <a className={`${buttonStyle} ${buttonSection}`} href={href}>
        {text}
      </a>
    );
  }

  return (
    <Link className={`${buttonStyle} ${buttonSection}`} to={href}>
      {text}
    </Link>
  );
}
