import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import * as styles from "./features.module.css";
import MarkdownText, { getText } from "../components/markdown-text";
import Link from "../components/link";
import LinkContainer from "../components/link-container";
import Section from "../components/section";
import Heading from "../components/heading";

export default function Features({ heading, secondaryHeading, content }) {
  return (
    <Section className={styles.featuresContainer}>
      <Heading center>{heading}</Heading>
      <Heading secondary center>
        {secondaryHeading}
      </Heading>
      <div className={styles.content}>
        {content.map((item, index) => (
          <Feature key={item.id} index={index}
            {...item}
            orientation={index % 2 === 0 ? "default" : "reverse"}
          />
        ))}
      </div>
    </Section>
  );
}

function Feature({
  index,
  primaryText,
  secondaryText,
  image,
  links,
  orientation = "default",
}) {
  const orientationStyle = orientation === "default" ? "" : styles.reverse;

  if (index === 0) {
  return (
    <div className={`${styles.featureContainer} ${orientationStyle}`}>
      <div className={styles.copyColumn}>
        <div className={styles.copyContainer}>
          <MarkdownText
            as="h4"
            className={styles.primaryText}
            {...primaryText}
          />
          <MarkdownText className={styles.secondaryText} {...secondaryText} />
          <LinkContainer>
            {links && links.map((link) => <Link key={link.id} {...link} />)}
          </LinkContainer>
        </div>
      </div>
      <div className={styles.imageColumn}>
        <StaticImage
          src="../images/effectivelearn.png" alt="Image"
        />
      </div>
    </div>
  );
  }
  else if (index === 1) {
    return (
      <div className={`${styles.featureContainer} ${orientationStyle}`}>
        <div className={styles.copyColumn}>
          <div className={styles.copyContainer}>
            <MarkdownText
              as="h4"
              className={styles.primaryText}
              {...primaryText}
            />
            <MarkdownText className={styles.secondaryText} {...secondaryText} />
            <LinkContainer>
              {links && links.map((link) => <Link key={link.id} {...link} />)}
            </LinkContainer>
          </div>
        </div>
        <div className={styles.imageColumn}>
          <StaticImage
            src="../images/websiteimage.png" alt="Image"
          />
        </div>
      </div>
    );
  }
  else if (index === 2) {
    return (
      <div className={`${styles.featureContainer} ${orientationStyle}`}>
        <div className={styles.copyColumn}>
          <div className={styles.copyContainer}>
            <MarkdownText
              as="h4"
              className={styles.primaryText}
              {...primaryText}
            />
            <MarkdownText className={styles.secondaryText} {...secondaryText} />
            <LinkContainer>
              {links && links.map((link) => <Link key={link.id} {...link} />)}
            </LinkContainer>
          </div>
        </div>
        <div className={styles.imageColumn}>
          <StaticImage
            src="../images/smartreporting.png" alt="Image"
          />
        </div>
      </div>
    );
  }
  else if (index === 3) {
    return (
      <div className={`${styles.featureContainer} ${orientationStyle}`}>
        <div className={styles.copyColumn}>
          <div className={styles.copyContainer}>
            <MarkdownText
              as="h4"
              className={styles.primaryText}
              {...primaryText}
            />
            <MarkdownText className={styles.secondaryText} {...secondaryText} />
            <LinkContainer>
              {links && links.map((link) => <Link key={link.id} {...link} />)}
            </LinkContainer>
          </div>
        </div>
        <div className={styles.imageColumn}>
          <StaticImage
            src="../images/sentencesincluded.png" alt="Image"
          />
        </div>
      </div>
    );
  }
  else {
    return(
      <div></div>
    )
  }
}
