import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./benefits.module.css";
import MarkdownText, { getText } from "../components/markdown-text";
import Link from "../components/link";
import LinkContainer from "../components/link-container";
import Section from "../components/section";
import Heading from "../components/heading";

export default function Benefits({ heading, secondaryHeading, content }) {
  return (
    <Section className={styles.benefitscontainer}>
      <Heading center>{heading}</Heading>
      <Heading secondary center className={styles.secondaryHeading}>
        {secondaryHeading}
      </Heading>
      <div className={styles.content}>
        {content.map((item, index) => (
          <BenefitContent key={item.id} index={index} {...item} />
        ))}
      </div>
    </Section>
  );
}

function BenefitContent({ index, primaryText, secondaryText, image, links = [] }) {
  if (index === 0) {
    return (
      <div className={styles.contentCard}>
        <StaticImage src="../images/effectivelearn.png" alt="Benefit Effectiveness" />
        <MarkdownText
          as="h3"
          className={styles.contentHeading}
          {...primaryText}
        />
        <MarkdownText {...secondaryText} />
        <LinkContainer>
          {links && links.map((link) => <Link key={link.id} {...link} />)}
        </LinkContainer>
      </div>
    );
  }

  else if (index === 1) {
    return (
      <div className={styles.contentCard}>
        <StaticImage src="../images/sentencesincluded.png" alt="Benefit Effectiveness" />
        <MarkdownText
          as="h3"
          className={styles.contentHeading}
          {...primaryText}
        />
        <MarkdownText {...secondaryText} />
        <LinkContainer>
          {links && links.map((link) => <Link key={link.id} {...link} />)}
        </LinkContainer>
      </div>
    );
  }

  else if (index === 2) {
    return (
      <div className={styles.contentCard}>
        <StaticImage src="../images/smartreporting.png" alt="Benefit Sentences" />
        <MarkdownText
          as="h3"
          className={styles.contentHeading}
          {...primaryText}
        />
        <MarkdownText {...secondaryText} />
        <LinkContainer>
          {links && links.map((link) => <Link key={link.id} {...link} />)}
        </LinkContainer>
      </div>
    );
  }

  else {
    return(
      <div></div>
    )
  }

}

